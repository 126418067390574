<script setup>
   import InputError from '@/Components/Forms/InputError.vue';
   import InputLabel from '@/Components/Forms/InputLabel.vue';
   import { Link, useForm } from '@inertiajs/vue3';
   import AppLayout from '@/Layouts/AppLayout.vue';
   import { trackUserInteraction } from '@/composables/useAnalytics.js';
   import { Button, InputText } from 'primevue';
   import Checkbox from 'primevue/checkbox';

   defineProps({
      canResetPassword: {
         type: Boolean,
      },
      status: {
         type: String,
      },
   });

   const form = useForm({
      email: '',
      password: '',
      remember: false,
   });

   const submit = () => {
      form.post(route('login'), {
         onFinish: () => form.reset('password'),
         onSuccess: () => trackUserInteraction('login'),
      });
   };
</script>

<template>
   <AppLayout
      :show-banner="false"
      :head-title="$t('account.login')"
   >
      <template #main>
         <div
            class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
         >
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
               <span class="sr-only">{{ $page.props.app_name }}</span>
               <img
                  class="mx-auto h-32 w-auto"
                  src="/images/logos/gravity_cy_logo.png"
                  :alt="$page.props.app_name"
               >
               <h2
                  class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
               >
                  {{ $t('account.sign_in_to_account') }}
               </h2>
            </div>

            <div class="p-4 sm:p-0 mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
               <div
                  class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12"
               >
                  <form
                     @submit.prevent="submit"
                     class="space-y-6"
                     action="#"
                     method="POST"
                  >
                     <div class="flex flex-col space-y-1">
                        <InputLabel
                           for="email"
                           :value="$t('contact.email')"
                        />

                        <InputText
                           size="small"
                           id="email"
                           type="email"
                           class="w-full"
                           v-model="form.email"
                           required
                           autofocus
                           autocomplete="username"
                        />

                        <InputError
                           :message="form.errors.email"
                        />
                     </div>

                     <div class="flex flex-col space-y-1">
                        <InputLabel
                           for="password"
                           :value="$t('account.password')"
                        />

                        <InputText
                           size="small"
                           id="password"
                           type="password"
                           class="w-full"
                           v-model="form.password"
                           required
                           autocomplete="current-password"
                        />

                        <InputError
                           :message="form.errors.password"
                        />
                     </div>

                     <div class="flex items-center justify-between">
                        <div class="flex items-center">
                           <Checkbox
                              binary
                              size="small"
                              name="remember"
                              v-model:checked="form.remember"
                           />
                           <span class="ms-2 text-sm text-gray-600">{{
                              $t('account.remember_me')
                           }}</span>
                        </div>

                        <div class="text-sm leading-6">
                           <Link
                              v-if="canResetPassword"
                              :href="route('password.request')"
                              class="underline text-sm text-gray-600 hover-animation hover:text-gray-900 rounded-md"
                           >
                              {{ $t('account.forgot_password') }}
                           </Link>
                        </div>
                     </div>

                     <div>
                        <Button
                           size="small"
                           type="submit"
                           class="flex w-full justify-center rounded-md"
                           :loading="form.processing"
                           :disabled="form.processing"
                           :label="$t('account.login')"
                        />
                     </div>
                  </form>
               </div>
            </div>

            <p class="mt-10 text-center text-sm text-gray-500">
               {{ $t('account.not_a_member') }}
               <Link
                  :href="route('register')"
                  class="font-semibold leading-6 hover-animation text-primary-color-dark hover:text-primary-color"
               >
                  {{ $t('account.register') }}
               </Link>
            </p>
         </div>
      </template>
   </AppLayout>
</template>
